import React from 'react';

const MenuItem = ({ title, description, price, subtitle }) => {
	const formattedPrice = (amount => {
		// add dollar sign for plain numbers, otherwise, leave it off
		if (!isNaN(amount)) return '$' + amount;

		return amount;
	})(price);

	return (
		<li className="menu-item">
			<span className="title">
				{title}
				<span className="subtitle">{subtitle}</span>
			</span>
			{description && <span className="description">{description}</span>}
			{price && <span className="price">{formattedPrice}</span>}
		</li>
	);
};

export default MenuItem;
