import React from 'react';

let section = 0;

const MenuSection = ({ title, description, children }) => {
	let sectionIdNumber = section++,
		sectionId = `menu-section-${sectionIdNumber}`;

	return (
		<div className="menu-section">
			<div className="menu-section-header">
				<h2 className="menu-section-title" id={sectionId}>
					{title}
				</h2>
				{description && (
					<span className="menu-section-description">
						{description}
					</span>
				)}
			</div>

			<ul className="menu-item-list" aria-labelledby={sectionId}>
				{children}
			</ul>
		</div>
	);
};

export default MenuSection;
