import React from 'react';
import MenuSection from '../components/Menu/MenuSection';
import MenuItem from '../components/Menu/MenuItem';
import Grid from '../components/Grid';
import { Link } from 'gatsby';
import { meats, sides } from '../data/catering.json';
import Layout from '../layouts/layout';

const Catering = () => (
	<Layout title="Catering">
		<div>
			<h1>Catering</h1>

			<section>
				<Link className="pink-button" to="/catering-request">
					Click here to submit a catering request now
				</Link>
				<br />
				<p>
					<br />
					Orders can also be placed with our Catering Director by
					calling{' '}
					<a href="tel:+17605250110">
						<span className="sr-only">
							Call our catering director at:{' '}
						</span>
						(760) 525-0110
					</a>
					.
				</p>

				<p>
					We know your catering needs vary. With this in mind, we have
					three basic styles. Each style is uniquely designed to fit
					your personal budget and needs.
				</p>

				<Grid>
					<MenuSection title="Meats">
						{meats.map(meat => (
							<MenuItem key={meat} title={meat} />
						))}
					</MenuSection>
					<MenuSection title="Sides">
						{sides.map(side => (
							<MenuItem key={side} title={side} />
						))}
					</MenuSection>
				</Grid>
			</section>

			<section>
				<h2>
					bare bones
					<span>drop-off catering</span>
				</h2>

				<p>
					The “Bare Bones” catering is our most budget-sensitive
					offer. Designed for groups of 15 or more people, “Bare
					Bones” offers great BBQ, our homemade BBQ sauce, dinner
					rolls, and condiments in an economical fashion. Your meal is
					prepared with the same expertise and quality as all our
					other offerings. We deliver your complete meal ready to
					serve 30-45 minutes prior to your scheduled eating time.
				</p>

				<MenuSection title="Menu Options/Price per person">
					<MenuItem title="One Meat + Two Sides" price="12" />
					<MenuItem title="Two Meats + Two Sides" price="14" />
					<MenuItem title="Three Meats + Two Sides" price="16" />
					<MenuItem title="Brisket" price="add $2 per person" />
					<MenuItem title="Ribs" price="add $2 per person" />
					<MenuItem title="Tri-tip" price="add $2 per person" />
					<MenuItem
						title="Additional Side"
						price="add $2 per person"
					/>
					<MenuItem title="Custom Meal" price="Call for pricing" />
				</MenuSection>
			</section>

			<section>
				<h2>
					half-rack
					<span>drop-off catering</span>
				</h2>

				<p>
					The “Half-Rack” catering is tailored for those desiring a
					“turn-key” presentation. We provide hot and delicious BBQ,
					our homemade BBQ sauce, dinner rolls, and condiments. We
					also provide disposable plates and flatware, disposable
					serving containers, and utensils. Your meal is delivered
					30-45 minutes prior to your scheduled eating time.
				</p>

				<MenuSection title="Menu Options/Price per person">
					<MenuItem title="One Meat + Two Sides" price="14" />
					<MenuItem title="Two Meats + Two Sides" price="16" />
					<MenuItem title="Three Meat + Two Sides" price="18" />
					<MenuItem title="Brisket" price="add $2 per person" />
					<MenuItem title="Ribs" price="add $2 per person" />
					<MenuItem title="Tri-tip" price="add $2 per person" />
					<MenuItem
						title="Additional Side"
						price="add $2 per person"
					/>
					<MenuItem title="Custom Meal" price="Call for pricing" />
				</MenuSection>
			</section>

			<section>
				<h2>
					full-rack
					<span>full-service catering</span>
				</h2>

				<p>
					With the “Full-Rack” offering, you get the whole kit and
					caboodle. Our When Pigs Fly BBQ staff remains on-site to
					set-up serving stations, serve our fabulous BBQ, including
					our homemade BBQ sauce, dinner rolls and condiments. Our
					staff will help your guests through the buffet lines, and
					stay to clean-up the BBQ serving area. This service also
					includes plates, flatware, napkins, serving tables, and
					tablecloths for the serving tables.
				</p>

				<MenuSection title="Menu Options/Price per person">
					<MenuItem title="One Meat + Two Sides" price="16" />
					<MenuItem title="Two Meats + Two Sides" price="18" />
					<MenuItem title="Three Meat + Two Sides" price="20" />
					<MenuItem title="Brisket" price="add $2 per person" />
					<MenuItem title="Ribs" price="add $2 per person" />
					<MenuItem title="Tri-tip" price="add $2 per person" />
					<MenuItem
						title="Additional Side"
						price="add $2 per person"
					/>
					<MenuItem title="Custom Meal" price="Call for pricing" />
				</MenuSection>
			</section>

			<section>
				<h2>the small print</h2>

				<dl>
					<dt>Pick up</dt>
					<dd>No minimum order amount for pick-up orders</dd>

					<dt>Delivery</dt>
					<dd>
						Delivery is available for orders of $100.00 or more.
						Delivery Charge is $20.00. An additional $1.50 per mile
						fee applies to all orders outside our primary area of
						service (5 miles). Delivery person may assist with set
						up and placement of product at time of delivery. Same
						day and next day deliveries available. However, we do
						prefer 48 hour notice for all catering deliveries to
						insure availability and preferred delivery time.
					</dd>

					<dt>Events</dt>
					<dd>
						Events requiring additional on-site hours are subject to
						additional fees.
					</dd>

					<dt>Payment</dt>
					<dd>
						We accept cash, business checks and all major credit
						cards.
					</dd>

					<dt>Cancellations</dt>
					<dd>
						72 hour cancellation notice is required. Late
						cancellations subject to forfeiture of deposit.
					</dd>
				</dl>
			</section>

			<Link className="pink-button" to="/catering-request">
				Submit a catering request
			</Link>
		</div>
	</Layout>
);

export default Catering;
